<template>
  <div class="unload-content__info">
    <r-block
      background="containers"
      radius
    >
      <r-title type="subtitle-1">
        Объект разгрузки: {{ cargo.type ? $t(cargo.type) : '-' }}
      </r-title>
      <r-block
        no-padding
        col
      >
        <unload-list-item :item="activeItem" />
      </r-block>
    </r-block>
    <r-block
      background="containers"
      radius
    >
      <r-title type="subtitle-1">
        Выбранные ТС для загрузки
      </r-title>
      <r-text
        v-if="!vehicles.length"
        type="caption"
      >
        Нет ТС для загрузки
      </r-text>
      <div
        v-else
        class="unload-content__info-list"
      >
        <r-block
          v-for="vehicle in vehicles"
          :key="vehicle.id"
          background="containers"
          radius
          col
          start
        >
          <r-icon
            name="truck-filled"
            :size="21"
          />
          <r-text>
            {{ vehicle.reg_number }}
          </r-text>
        </r-block>
      </div>
    </r-block>
  </div>
</template>

<script>
export default {
  components: {
    unloadListItem: () => import('../unload-list/unload-list-item.vue')
  },
  props: {
    cargo: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeItem() {
      return this.$store.state.unload.activeItem || null
    },
    vehicles() {
      return this.cargo?.cargoes?.reduce((a, c) => {
        const cargo_places = c.cargo_places || []

        cargo_places.filter(cp => cp.vehicle).map(cp => cp.vehicle).forEach(v => {
          if (!a.find(av => av.id === v.id)) {
            a.push(v)
          }
        })

        return a
      }, []) || []
    }
  }
}
</script>
<style lang="scss">
.unload-content__info {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;

  &-list {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}
</style>

<i18n>
{
  "ru": {
    "railcars": "Вагон",
    "vehicles": "ТС",
    "boat": "Баржа"
  }
}
</i18n>
